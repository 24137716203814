import {
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNotNullishObject,
  validateOfExpectedValue,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  AuthorizationMessage,
  AutodeskLegacyProjectIntegration,
  ProjectIntegration,
  ProcoreLegacyProjectIntegration,
  ProjectIntegrationsMap,
  ResponseError,
} from "@services/integrations-service/integrations-service-types";
import { CoreAPIUtils, SphereDashboardAPITypes } from "@stellar/api-logic";
import { CANCELED_AUTHORIZATION_MESSAGE } from "@services/integrations-service/integrations-constants";

/**
 * @returns True if the passed data is of type IntegrationToken
 * @param data Data to validate
 */
export function isIntegrationToken(
  data: unknown
): data is SphereDashboardAPITypes.IntegrationToken {
  return (
    validateNotNullishObject(data, "IntegrationToken") &&
    validateEnumValue(data.provider, SphereDashboardAPITypes.IntegrationId) &&
    validatePrimitive(data, "expiration", "number", PropOptional) &&
    validateArrayOf({
      object: data,
      prop: "scopes",
      elementGuard: (el) => typeof el === "string",
    })
  );
}

/**
 * @returns True if the passed data is of type AutodeskLegacyProjectIntegration
 * @param data Data to validate
 */
export function isAutodeskLegacyProjectIntegration(
  data: unknown
): data is AutodeskLegacyProjectIntegration {
  return (
    validateNotNullishObject(data, "AutodeskLegacyProjectIntegration") &&
    validatePrimitive(data, "projectName", "string") &&
    validatePrimitive(data, "projectId", "string") &&
    validatePrimitive(data, "hubId", "string") &&
    validatePrimitive(data, "providerId", "string") &&
    validateOfExpectedValue(data, "providerId", "autodesk")
  );
}

/**
 * @returns True if the passed data is of type ProcoreLegacyProjectIntegration
 * @param data Data to validate
 */
export function isProcoreLegacyProjectIntegration(
  data: unknown
): data is ProcoreLegacyProjectIntegration {
  return (
    validateNotNullishObject(data, "ProcoreLegacyProjectIntegration") &&
    validatePrimitive(data, "projectName", "string") &&
    validatePrimitive(data, "projectId", "string") &&
    validatePrimitive(data, "companyId", "string") &&
    validatePrimitive(data, "providerId", "string") &&
    validateOfExpectedValue(data, "providerId", "procore")
  );
}

/**
 * @returns True if the passed data is of type ProjectIntegration
 * @param data Data to validate
 */
export function isProjectIntegration(
  data: unknown
): data is ProjectIntegration {
  return (
    validateNotNullishObject<ProjectIntegration>(data, "ProjectIntegration") &&
    validatePrimitive(data, "bcfProjectId", "string", PropOptional)
  );
}

/**
 * @returns True if the passed data is of type ProjectIntegrations
 * @param data Data to validate
 */
export function isProjectIntegrations(
  data: unknown
): data is ProjectIntegrationsMap {
  return (
    validateNotNullishObject(data, "ProjectIntegrations") &&
    validateOfType(
      data,
      "autodesk",
      isAutodeskLegacyProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "procore",
      isProcoreLegacyProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "autodesk-bim360.issues",
      isProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "autodesk-bim360.rfis",
      isProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "autodesk-acc.issues",
      isProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "autodesk-acc.rfis",
      isProjectIntegration,
      PropOptional
    ) &&
    validateOfType(
      data,
      "procore.observations",
      isProjectIntegration,
      PropOptional
    ) &&
    validateOfType(data, "procore.rfis", isProjectIntegration, PropOptional)
  );
}

/**
 * @returns True if the passed data is of type AuthorizationMessage
 * @param data Data to validate
 */
export function isAuthorizationMessage(
  data: unknown
): data is AuthorizationMessage {
  return (
    validateNotNullishObject<AuthorizationMessage>(
      data,
      "AuthorizationMessage"
    ) &&
    validatePrimitive(data, "connectStatus", "boolean") &&
    validatePrimitive(data, "message", "string") &&
    validateEnumValue(data.providerId, SphereDashboardAPITypes.IntegrationId) &&
    (validateOfExpectedValue(data, "status", "success") ||
      validateOfExpectedValue(data, "status", "error"))
  );
}

/**
 * @returns True if the passed data is a canceled authorization message
 * @param data Data to validate
 */
export function isCanceledAuthorizationMessage(data: unknown): boolean {
  return typeof data === "string" && data === CANCELED_AUTHORIZATION_MESSAGE;
}

/**
 * @returns True if the passed data is of type ResponseError
 * @param data Data to validate
 */
export function isResponseError(
  data: unknown
): data is ResponseError {
  return CoreAPIUtils.isResponseError(data) && validatePrimitive(data, "message", "string");
}
