import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { ReactSetStateFunction } from "@custom-types/types";
import { useToast } from "@hooks/use-toast";
import {
  IntegrationDialogCallbacks,
  IntegrationDialogTypes,
} from "@pages/integrations/integrations-types";
import { TokensService } from "@services/integrations-service/tokens-service";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useEffect, useMemo, useState } from "react";

interface ReturnProps extends IntegrationDialogCallbacks {
  /** Selected Dialog for integration */
  selectedDialog: IntegrationDialogTypes | null;

  /** Setter for selected Dialog for integration */
  setSelectedDialog: ReactSetStateFunction<IntegrationDialogTypes | null>;

  /** All the integrations */
  allIntegrations: {
    /** All the enabled integrations */
    enabledIntegrationIds: SphereDashboardAPITypes.IntegrationId[];

    /** All the available integrations */
    availableIntegrationIds: SphereDashboardAPITypes.IntegrationId[];
  };

  /** Enable an integration */
  onEnableIntegration: (
    integrationId: SphereDashboardAPITypes.IntegrationId
  ) => Promise<void>;

  /** Flag to determine whether the integration still loading */
  isLoadingIntegrations: boolean;
}

/** All the hooks that are used for integration process in workspace level */
export function useWorkspaceIntegrations(
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId
): ReturnProps {
  const [selectedDialog, setSelectedDialog] =
    useState<IntegrationDialogTypes | null>(null);

  const [tokens, setTokens] = useState<
    SphereDashboardAPITypes.IntegrationToken[]
  >([]);
  const [isLoadingIntegrations, setIsLoadingIntegrations] =
    useState<boolean>(true);

  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  const tokensService = useMemo(() => new TokensService({ coreApiClient }), [coreApiClient]);

  /** Filtering the available integrations and enabled integrations */
  const allIntegrations = useMemo(() => {
    const integrationIds = Object.values(SphereDashboardAPITypes.IntegrationId);
    const enabledIntegrationIds = integrationIds.filter((id) =>
      tokens.some((token) => token.provider === id)
    );
    const availableIntegrationIds = integrationIds.filter(
      (id) => !enabledIntegrationIds.includes(id)
    );

    return {
      enabledIntegrationIds,
      availableIntegrationIds,
    };
  }, [tokens]);

  /** Fetches all integration token from backend through integration service */
  useEffect(() => {
    async function getIntegrationTokens(): Promise<void> {
      try {
        const integrationTokens =
          await tokensService.getIntegrationTokens();
        setTokens(integrationTokens);
      } catch (error) {
        handleErrorWithToast({
          id: `getIntegrationTokens-${Date.now().toString()}`,
          title: "Failed to get the integration tokens.",
          error,
        });
      } finally {
        setIsLoadingIntegrations(false);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    getIntegrationTokens();
  }, [handleErrorWithToast, tokensService]);

  /** Enable an integration */
  const onEnableIntegration = useCallback(
    async (
      integrationId: SphereDashboardAPITypes.IntegrationId
    ): Promise<void> => {
      trackEvent({
        name: WorkspaceEvents.connectIntegration,
        props: { integrationId },
      });

      setSelectedDialog("inProgress");

      try {
        const integrationTokens =
          await tokensService.authorizeIntegration(integrationId);
        setTokens(integrationTokens);
        setSelectedDialog("success");
      } catch (error) {
        handleErrorWithToast({
          id: `authorizeIntegration-${Date.now().toString()}`,
          title: "Failed to authorize the integration.",
          error,
        });
        setSelectedDialog(null);
      }
    },
    [handleErrorWithToast, tokensService, trackEvent]
  );

  /** Disable an integration */
  const onDisableIntegration = useCallback(
    async (
      integrationId: SphereDashboardAPITypes.IntegrationId
    ): Promise<void> => {
      trackEvent({
        name: WorkspaceEvents.disconnectIntegration,
        props: { integrationId },
      });

      setSelectedDialog("disconnect");

      try {
        const integrationTokens = await tokensService.revokeAuthorization(
          integrationId
        );
        setTokens(integrationTokens);
        showToast({
          message: "Integration disabled",
          type: "success",
        });
      } catch (error) {
        handleErrorWithToast({
          id: `disableIntegration-${Date.now().toString()}`,
          title: "Failed to disable the integration.",
          error,
        });
      }
      setSelectedDialog(null);
    },
    [handleErrorWithToast, tokensService, showToast, trackEvent]
  );

  /** Close the other tab if user cancel the integration enabling process from dashboard tab */
  const onCloseAuthorizationWindow = useCallback(() => {
    tokensService.closeAuthorizationWindow();
    setSelectedDialog(null);
  }, [tokensService]);

  /** Callback when confirm button clicked on success dialog */
  function onSuccessDialogConfirm(): void {
    setSelectedDialog(null);
  }

  /** Callback when cancel button clicked on in progress dialog */
  function onInProgressDialogCancel(): void {
    onCloseAuthorizationWindow();
  }

  /** Callback when confirm button clicked on disconnect dialog */
  function onDisconnectDialogConfirm(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    onDisableIntegration(selectedIntegrationId);
  }

  /** Callback when cancel button clicked on disconnect dialog */
  function onDisconnectDialogCancel(): void {
    setSelectedDialog(null);
  }

  return {
    allIntegrations,
    isLoadingIntegrations,
    onEnableIntegration,
    selectedDialog,
    setSelectedDialog,
    onSuccessDialogConfirm,
    onInProgressDialogCancel,
    onDisconnectDialogConfirm,
    onDisconnectDialogCancel,
  };
}
